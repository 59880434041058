// URLs
import {
  USER_SESSIONS_ADMIN_URL,
  FREE_PASS_URL
} from './config';
// Types
import {
  BaseDataResponseModel,
  BaseResponseModel,
  IAdminSessions,
  IFreePass
} from './types';

import http from '@/plugins/axios';

class AdminService {
  fetchUserSessionsAdmin(password: string): BaseResponseModel<BaseDataResponseModel<Array<IAdminSessions>>> {
    return http.get(USER_SESSIONS_ADMIN_URL, {
      headers: {
        Authentication: password
      }
    });
  }

  fetchFreePasses(password: string): BaseResponseModel<BaseDataResponseModel<Array<IFreePass>>> {
    return http.get(FREE_PASS_URL, {
      headers: {
        Authentication: password
      }
    });
  }

  createFreePass(code: string, password: string): BaseResponseModel<BaseDataResponseModel<IFreePass>> {
    return http.post(FREE_PASS_URL, { code }, {
      headers: {
        Authentication: password
      }
    });
  }

  deleteFreePass(id: string, password: string): BaseResponseModel<BaseDataResponseModel<IFreePass>> {
    return http.delete(`${FREE_PASS_URL}/${id}`, {
      headers: {
        Authentication: password
      }
    });
  }

  useFreePass(code: string, session_id: string): BaseResponseModel<BaseDataResponseModel<string>> {
    return http.post(`${FREE_PASS_URL}/use`, {}, {
      headers: {
        SessionId: session_id,
        Code: code
      }
    });
  }
}

export default new AdminService();

