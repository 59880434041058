import { AxiosError } from 'axios';
import { defineStore, Store } from 'pinia';

import { BaseStoreState } from '../types';

import { useError } from '@/hooks/useError';
import AdminService from '@/services/adminService';
import { IAdminSessions, IFreePass } from '@/services/types';
import { store } from '@/store';

// Types
interface AdminState extends BaseStoreState {
  user_session_list: Array<IAdminSessions>;
  free_passes: Array<IFreePass>;
  password: string;
}

// Initial State
const InitialAdminState: AdminState = {
  user_session_list: [],
  free_passes: [],
  password: '',
  errors: []
};

const { getApiErrorResponse, clearError } = useError();

export const useAdminStore = defineStore('admin', {
  state: (): AdminState => (InitialAdminState),
  actions: {
    async fetchUserSessionsAdmin(password: string) {
      clearError(this.errors, 'fetchUserSessionsAdmin');
      try {
        const { data } = await AdminService.fetchUserSessionsAdmin(password);
        this.user_session_list = data.data;
        this.password = password;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'userSessionsAdmin'));
      }
    },
    async fetchFreePasses(password: string) {
      clearError(this.errors, 'fetchFreePasses');
      try {
        const { data } = await AdminService.fetchFreePasses(password);
        this.free_passes = data.data;
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'fetchFreePasses'));
      }
    },
    async createFreePass(code: string) {
      clearError(this.errors, 'createFreePass');
      try {
        await AdminService.createFreePass(code, this.password);
        await this.fetchFreePasses(this.password);
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'createFreePass'));
      }
    },
    async deleteFreePass(id: string) {
      clearError(this.errors, 'deleteFreePass');
      try {
        await AdminService.deleteFreePass(id, this.password);
        await this.fetchFreePasses(this.password);
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'deleteFreePass'));
      }
    },
    async useFreePass(code: string, session_id: string) {
      clearError(this.errors, 'useFreePass');
      try {
        await AdminService.useFreePass(code, session_id);
      } catch (error) {
        this.errors.push(getApiErrorResponse(error as AxiosError, 'useFreePass', 'Geçersiz kod!'));
      }
    }
  }
});

// Need to be used outside the setup
export function useAdminStoreWithoutSetup(): Store {
  return useAdminStore(store);
}
