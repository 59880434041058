
import { parse, format } from 'date-fns';
import { tr } from 'date-fns/locale';
export function dateParse(date: string, formatString = "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS"): Date {
  return parse(date, formatString, new Date());
}

export function dateFormat(date: string, output_format: string): string {
  return format(dateParse(date), output_format, { locale: tr });
}

